@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;

    --chart-1: 12 76% 61%;

    --chart-2: 173 58% 39%;

    --chart-3: 197 37% 24%;

    --chart-4: 43 74% 66%;

    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }

  h1 {
    @apply text-4xl font-bold tracking-tight lg:text-5xl;
  }

  h2 {
    @apply text-3xl font-semibold tracking-tight;
  }

  h3 {
    @apply text-2xl font-semibold tracking-tight;
  }

  h4 {
    @apply text-xl font-semibold tracking-tight;
  }

  p {
    @apply leading-7 [&:not(:first-child)]:mt-6;
  }

  .link {
    @apply text-primary hover:text-primary/90;
  }
}

.nav-link {
  @apply rounded-md px-6 py-2 hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground focus:outline-none;
}

/* Post styles */

.prose .anchor {
  @apply invisible absolute no-underline;

  margin-left: -1em;
  padding-right: 0.5em;
  width: 80%;
  max-width: 700px;
  cursor: pointer;
}

.anchor:hover {
  @apply visible;
}

.prose a {
  @apply decoration-neutral-400 decoration-[0.1em] underline-offset-2 transition-all dark:decoration-neutral-600;
}

.prose .anchor:after {
  @apply text-neutral-300 dark:text-neutral-700;
  content: "#";
}

.prose *:hover > .anchor {
  @apply visible;
}

.prose pre {
  @apply border border-neutral-800;
}

.prose code {
  @apply rounded-lg border border-neutral-100 px-1 py-0.5 text-neutral-800 dark:border-neutral-800 dark:text-neutral-200;
}

.prose pre code {
  @apply text-neutral-800 dark:text-neutral-200;
  border: initial;
}

.prose img {
  /* Don't apply styles to next/image */
  @apply m-0;
}

.prose > :first-child {
  /* Override removing top margin, causing layout shift */
  margin-top: 1.25em !important;
  margin-bottom: 1.25em !important;
}

code[class*="language-"],
pre[class*="language-"] {
  @apply text-neutral-50;
}

pre::-webkit-scrollbar {
  display: none;
}

pre {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Remove Safari input shadow on mobile */
input[type="text"],
input[type="email"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.prose .tweet a {
  text-decoration: inherit;
  font-weight: inherit;
}

.prose .callout > p {
  margin: 0 !important;
}

[data-rehype-pretty-code-fragment] code {
  @apply grid min-w-full break-words rounded-none border-0 bg-transparent p-0 text-sm text-black;
  counter-reset: line;
  box-decoration-break: clone;
}
[data-rehype-pretty-code-fragment] .line {
  @apply py-1;
}
[data-rehype-pretty-code-fragment] [data-line-numbers] > .line::before {
  counter-increment: line;
  content: counter(line);
  display: inline-block;
  width: 1rem;
  margin-right: 1rem;
  text-align: right;
  color: gray;
}
[data-rehype-pretty-code-fragment] .line--highlighted {
  @apply bg-slate-500 bg-opacity-10;
}
[data-rehype-pretty-code-fragment] .line-highlighted span {
  @apply relative;
}
[data-rehype-pretty-code-fragment] .word--highlighted {
  @apply rounded-md bg-slate-500 bg-opacity-10 p-1;
}
[data-rehype-pretty-code-title] {
  @apply rounded-t-lg border border-[#333333] bg-[#1c1c1c] px-4 py-3 font-mono text-xs font-medium text-neutral-200;
}
[data-rehype-pretty-code-title] + pre {
  @apply mt-0 rounded-t-none border-t-0;
}
